export default {
  get(state) {
    const files = require.context('~/assets/content/news/', false, /\.json$/)
    const articles = files.keys().map(key => {
      const res = files(key)
      res.slug = key.slice(2, -5)
      return res
    })
    return articles
  }
}
