<template>
  <div>
    <Nav />
    <nuxt class="relative pt-24 min-h-screen" />
    <Footer />
    <Alert />
  </div>
</template>
<script>
import Nav from '~/components/global/Nav.vue'
import Footer from '~/components/global/Footer.vue'
import Alert from '~/components/widgets/alerts/CookieAlert.vue'

export default {
  components: {
    Nav,
    Footer,
    Alert
  },
  head() {
    return {
      title: 'Sutura | an innovative digital design agency',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Sutura is a web design and branding agency working to solve challenges for brands we believe in through digital transformation.'
        }
      ]
    }
  }
}
</script>
