import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3974a0b3 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ba59494a = () => interopDefault(import('../pages/customers.vue' /* webpackChunkName: "pages/customers" */))
const _58c4c500 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6235e27c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _3d6d38ea = () => interopDefault(import('../pages/works/index.vue' /* webpackChunkName: "pages/works/index" */))
const _3d212040 = () => interopDefault(import('../pages/news/_news.vue' /* webpackChunkName: "pages/news/_news" */))
const _07471a9a = () => interopDefault(import('../pages/works/_works.vue' /* webpackChunkName: "pages/works/_works" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _44b764da,
    name: "about"
  }, {
    path: "/account",
    component: _3974a0b3,
    name: "account"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact"
  }, {
    path: "/customers",
    component: _ba59494a,
    name: "customers"
  }, {
    path: "/news",
    component: _58c4c500,
    name: "news"
  }, {
    path: "/test",
    component: _6235e27c,
    name: "test"
  }, {
    path: "/works",
    component: _3d6d38ea,
    name: "works"
  }, {
    path: "/news/:news",
    component: _3d212040,
    name: "news-news"
  }, {
    path: "/works/:works",
    component: _07471a9a,
    name: "works-works"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
