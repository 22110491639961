<template>
  <div
    style="background-color: #f9f9f9;"
    class="overflow-y-hidden font-hel mt-12 pt-12 md:px-16 px-5 w-full flex flex-wrap xl:px-32  h-screen"
  >
    <div ref="content" class="my-auto w-full text-center">
      <div class="text-3xl md:text-7xl">
        <span class="font-bold uppercase font-bold">👋 Have an idea?</span>
        <br />
        <span class="font-regular border-b-2">Tell us about it</span>
      </div>
      <div class="flex flex-wrap w-full mt-24 text-center">
        <div class="w-full ">
          <span class="border-b text-2xl">
            hello@suturacreative.com
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      animation: null
    }
  },
  watch: {
    $route(to, from) {}
  },
  mounted() {
    this.$nextTick(function() {})
  }
}
</script>
