export default {
  get(state) {
    const files = require.context('~/assets/content/works/', false, /\.json$/)
    const works = files.keys().map(key => {
      const res = files(key)
      res.slug = key.slice(2, -5)
      return res
    })
    return works
  },
  featured(state) {
    const file = require('~/assets/content/pages/home.json')

    const featuredArticles = []

    for (let i = 0; i < file['featured-work'].length; i++) {
      const workEndpoint = require('~/assets/content/works/' +
        file['featured-work'][i].work +
        '.json')
      workEndpoint.slug = file['featured-work'][i].work

      featuredArticles.push(workEndpoint)
    }

    return featuredArticles
  }
}
