<template>
  <div
    v-if="status === 'active'"
    style="transition: width .25s ease-out;"
    class="shadow-lg hidden z-30 mb-12 rounded-full fixed flex justify-between inset-x-0 mx-auto bottom-0 text-white w-11/12 md:w-1/2 lg:w-1/3 text-center bg-black"
  >
    <div ref="message" class="hidden px-8 py-3 ">
      This website uses cookies 🍪
    </div>
    <div class="absolute right-0 px-4 bg-gray-400 py-3 rounded-full">
      <div
        ref="message-text"
        class="text-gray-400 cursor-pointer"
        @click="$store.commit('settings/setAlertState', 'hide')"
      >
        Ok
      </div>
    </div>
  </div>
</template>
<script>
import { TimelineLite, Power4, Elastic } from 'gsap'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      animation: new TimelineLite()
    }
  },
  computed: {
    ...mapState({
      status: state => state.settings.alert
    })
  },
  mounted() {
    this.$nextTick(function() {
      const thisObj = this
      this.animation
        .set(this.$el, {
          delay: 4,
          width: 50,
          height: 50,
          ease: Power4.easeOut,
          onComplete() {
            thisObj.$el.classList.remove('hidden')
          }
        })
        .from(this.$el, 0.75, {
          alpha: 0,
          y: '+=40',
          ease: Elastic.easeOut
        })
        .set(this.$el, {
          ease: Power4.easeOut,
          clearProps: 'width',
          onComplete() {
            thisObj.$refs.message.classList.remove('hidden')
            thisObj.$refs['message-text'].classList.remove('text-gray-400')
            thisObj.$refs['message-text'].classList.add('text-black')
          }
        })
        .from([thisObj.$refs.message, thisObj.$refs['message-text']], 0.45, {
          delay: 0.5,
          alpha: 0,
          ease: Power4.easeOut
        })
    })
  }
}
</script>
